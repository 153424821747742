import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/LoginPage';
import ResetPassword from './components/ResetPassword';
import Home from './components/HomePage';
import SetupPassword from './components/SetupPassword';
import supabase from './services/supabase';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user || null);
      setLoading(false);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You might want to style this better
  }

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route 
            path="/setup-password" 
            element={<SetupPassword />} 
          />
          <Route 
            path="/reset-password" 
            element={<ResetPassword />} 
          />
          <Route 
            path="/" 
            element={
              user ? <Home user={user} /> : <Login />
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
