import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../services/supabase';
import logo from './Sitruna_Logo_no_bg_white_i.png';
import lemon1 from "./lemon1.png";
import lemon2 from "./lemon2.png";
import lemon3 from "./lemon3.png";
import "../App.css";

const SetupPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we're in an invite flow
    const handleInviteToken = async () => {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const refreshToken = hashParams.get('refresh_token');
      const type = hashParams.get('type');

      if (type === 'invite' && accessToken) {
        try {
          const { error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (error) throw error;
          
          // Clear the URL hash but stay on the setup page
          window.history.replaceState(null, '', window.location.pathname);
        } catch (err) {
          console.error('Error setting session:', err);
          setError('Invalid or expired invite link. Please request a new one.');
          navigate('/');
        }
      }
    };

    handleInviteToken();
  }, [navigate]);

  const handleSetPassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters");
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccess(true);
      // Sign out after successful password setup
      await supabase.auth.signOut();
      
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-wrapper">
      <img src={lemon1} alt="Lemon" className="lemon lemon-1" />
      <img src={lemon2} alt="Lemon" className="lemon lemon-2" />
      <img src={lemon3} alt="Lemon" className="lemon lemon-3" />
      <div className="login-container">
        <div className="login-logo-container">
          <img src={logo} alt="Sitruna Logo" className="login-logo" />
        </div>
        <h2 className="login-title">Welcome to Sitruna DashPort</h2>
        <div className="login-form-container">
          <p style={{ textAlign: 'center', marginBottom: '20px' }}>
            Please set up your password to continue
          </p>
          <div className="login-form-card">
            <input
              type="password"
              className="login-input"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              className="login-input"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button className="login-button" onClick={handleSetPassword}>
            Set Password
          </button>
          {error && (
            <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
              {error}
            </div>
          )}
          {success && (
            <div style={{ color: 'green', marginTop: '10px', textAlign: 'center' }}>
              Password set successfully! You can now log in.
            </div>
          )}
        </div>
      </div>
      <div className="login-footer">
        &copy; 2025 Sitruna Consulting Limited
      </div>
    </div>
  );
};

export default SetupPassword;