import React, { useState } from "react";
import supabase from "../services/supabase";
import "../App.css";
import logo from "./Sitruna_Logo_no_bg_white_i.png";
import lemon1 from "./lemon1.png";
import lemon2 from "./lemon2.png";
import lemon3 from "./lemon3.png";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetStatus, setResetStatus] = useState("");

  const handleLogin = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: loginEmail,
      password: loginPassword
    });
    if (error) alert('Error logging in: ' + error.message);
  };

  const handlePasswordReset = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(loginEmail, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    
    if (error) {
      setResetStatus('Error sending reset email: ' + error.message);
    } else {
      setResetStatus('Password reset email sent! Please check your inbox.');
    }
  };

  const handleGoogleLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}`
      }
    });
    if (error) alert('Error logging in with Google: ' + error.message);
  };

  return (
    <div className="login-wrapper">
      <img src={lemon1} alt="Lemon" className="lemon lemon-1" />
      <img src={lemon2} alt="Lemon" className="lemon lemon-2" />
      <img src={lemon3} alt="Lemon" className="lemon lemon-3" />
      <div className="login-container">
        <div className="login-logo-container">
          <img src={logo} alt="Sitruna Logo" className="login-logo" />
        </div>
        <h2 className="login-title">Sign in to your account</h2>
        <div className="login-form-container">
          <div className="login-form-card">
            <input
              id="email"
              type="email"
              className="login-input"
              placeholder="Email address"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
            {!isResetMode && (
              <input
                id="password"
                type="password"
                className="login-input"
                placeholder="Password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            )}
          </div>
          {!isResetMode ? (
            <>
              <button className="login-button" onClick={handleLogin}>
                Login
              </button>
              <button 
                className="login-button google-button" 
                onClick={handleGoogleLogin}
                style={{ 
                  marginTop: '10px',
                  backgroundColor: '#fff',
                  color: '#2d3647',
                  border: '1px solid #2d3647',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img 
                  src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" 
                  alt="Google"
                  style={{ width: '20px', height: '20px' }}
                />
                Sign in with Google
              </button>
              <button
                onClick={() => setIsResetMode(true)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#2d3647',
                  marginTop: '15px',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                Forgot Password?
              </button>
            </>
          ) : (
            <>
              <button className="login-button" onClick={handlePasswordReset}>
                Send Reset Link
              </button>
              <button
                onClick={() => setIsResetMode(false)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#2d3647',
                  marginTop: '15px',
                  cursor: 'pointer'
                }}
              >
                Back to Login
              </button>
            </>
          )}
          {resetStatus && (
            <div style={{ marginTop: '15px', textAlign: 'center', color: resetStatus.includes('Error') ? 'red' : 'green' }}>
              {resetStatus}
            </div>
          )}
        </div>
      </div>
      <div className="login-footer">
        &copy; 2025 Sitruna Consulting Limited
      </div>
    </div>
  );
};

export default Login;
